@charset "utf-8";

.timeline-card {
    position: relative;
    width: 45%;
    background-color: $dark;
    border-radius: 1em;

    &:nth-child(even) {
        margin-left: auto;
        margin-right: 0;
        ::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 11%;
            height: 5px;
            transform: translate(-100%, -5px);
            background-color: $warning;
        }
    }

    &:nth-child(odd) {
        ::after{
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 11%;
            height: 5px;
            transform: translate(100%, -5px);
            background-color: $warning;
        }
    }

    .timeline-card-text {
        padding: 0 15px 10px;
    }
}

.timeline-line {
    position: absolute;
    width: 5px;
    top:35px;
    bottom:0;
    background-color: $warning;
    border-radius: 1em;
    z-index: 99;
    transform: translateX(-2.5px);
}

#skills {
    img {
        height:128px;
    }
}