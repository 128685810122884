@charset "utf-8";

@media screen and (max-width: 650px) {
    .hero-body {
        padding: 0;
        img {
            right: 0 !important;
            object-fit: cover;
        }
    }
}

@media screen and (max-width: 450px) {
    #profile-pic {
        object-fit: cover;
    }
    .timeline-card {
        width: 100%;
        z-index: 100;
        ::before, ::after {
            display: none;
        }
        margin-bottom: 50px;
    }
}