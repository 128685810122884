@charset "utf-8";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "~bulmaswatch/solar/_variables.scss";
@import "~bulma/bulma.sass";
@import "~bulmaswatch/solar/_overrides.scss";

@import "_fonts.scss";
@import "~highlight.js/scss/github-dark";

h1 {
  font-size: 2em;  
}
h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.17em;
}
p {
    text-align: justify;
}
img {
    position: relative;
    display: block;
    margin: 20px auto;
}
.code-container {
    margin: 20px 0;
    background-color: #073642;
    border-radius: .5em;
    .code-title {
        text-align: center;
        display: block;
        margin-bottom: 5px;
        font-size: 20px;
        font-style: italic;
        font-weight: bold;
    }
    .code {
        border-radius: .5em;
        pre {
            border-radius: .5em;
            padding-top: 0;
        }
    }
}
ul {
    list-style: inside;
}
:not(.code) > pre {
    margin: 0 0 20px;
}
code {
    color: $success;
}

.container {
    width: 50%;
}
@media screen and (max-width: 1440px) {
    .container {
        width: 100%;
    }
}
.to-top-button {
    background-color: #073642;
    color: #859900;
    position:fixed;
    right: 40px;
    bottom: 40px;
    z-index: 1;
    padding: 10px 10px;
    border-radius: 2em;
    display: none;
    cursor: pointer;
}