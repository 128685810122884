@charset "utf-8";

html, body {
    min-height: 100vh;
    overflow: auto !important;
    font-family: 'Ubuntu Mono', 'Courier New', Courier, monospace;
}

nav {
    font-size: 22px;
}

nav {
    .buttons {
        a {
            color: #b58900;
        }
    }
}

#sensilla:hover {
    filter: brightness(1.75);
    transition: filter 0.15s;
}