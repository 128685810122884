@charset "utf-8";

@font-face {
    font-family: 'Ubuntu Mono';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/UbuntuMono-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu Mono';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/UbuntuMono-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu Mono';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/UbuntuMono-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu Mono';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/UbuntuMono-BoldItalic.ttf') format('truetype');
}